<template>
  <div class="h-full flex-container">
    <div class="console-dash-menu-container">
      <div class="console-dash-menu console-dash-menu-level1" :class="{maxWidth: $route.name === 'console.clientes'}">
        <u-btn @click="$router.push({name: 'console.tarefas.kanban'})" class="opt-btn" :class="{active: $route.name === 'console.tarefas.kanban'}"><i class="fa-duotone fa-chart-kanban" /> Board</u-btn>
        <u-btn @click="$router.push({name: 'console.tarefas'})" class="opt-btn" :class="{active: $route.name === 'console.tarefas'}"><i class="fal fa-square-list" /> Lista</u-btn>
      </div>
    </div>
    <div v-show="$route.name === 'console.tarefas'" class="console-tarefas console-pad">
      <div class="cliente-tarefas">
        <task-list-page ref="list" :autoload="true"/>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>

import TaskListPage from "@/components/console/components/suporte/tarefas/TarefasList.vue"

export default {
  name: "ClienteTarefas",
  data() {
    return {
      response: {},
    }
  },
  components: {
    TaskListPage
  },
  mounted() {
  },
  methods: {
  }
}
</script>
